import React, { useEffect, useContext, useCallback, useRef, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ButtonIcon from '@hpstellar/core/visId/ButtonIcon';
import { Delete, X } from '@hpstellar/vis-icons';

import SearchIcon from './search-bar-search-icon';
import SearchAutocompleteContext from './search-autocomplete-context';
import ActionTextBox from '../../page/components/header-footer/action-textbox';
import Icon from '../../ui/components/icon';
import SearchPageBreadcrumb from './search-page-breadcrumb';
import useUserData, { getPStoreID } from '../../hooks/useUserData';

import './css/search-bar.less';

const ClearButton = props => {
    const { onClear } = props || {};
    return (
        <ButtonIcon
            icon={<Delete />}
            variant="primary"
            theme="dark"
            className="searchClearIcon"
            size="large"
            onKeyDown={e => {
                e.stopPropagation();
                if (e.key === 'Enter') onClear();
            }}
            aria-label="Clear search term"
            onClick={onClear}
        />
    );
};

export default props => {
    const { inputId = 'globalSearch', closeSearch } = props;
    const {
        autocompleteModalOpen,
        setAutocompleteModalOpen,
        autocompleteInputFocused,
        setAutocompleteInputFocused,
        searchKey,
        setSearchKey,
        resetAutocompletePreviewData,
        setSearchKeyword,
        closeModalAndInputBar,
        setMobileInputOpen,
        mobileInputOpen,
        initialSearchKey,
        productSrpLink,
        getAutocompleteLinkData,
        autocompleteWithBreadcrumb,
        autocompleteTrendingKeywordsType,
        setAutocompleteStateOnClick,
        singleProductUrl,
        redirectLocation
    } = useContext(SearchAutocompleteContext);

    const history = useHistory();
    const { userData } = useUserData();
    const { updatedFromService } = userData || {};
    const pStoreID = getPStoreID(userData);

    const isMobile = useSelector(
        state =>
            state &&
            state.ui &&
            state.ui.width &&
            state.ui.customBreakpoints &&
            state.ui.customBreakpoints.unifiedSearch === 'mobile'
    );
    const inputProps = {
        onMouseOver: resetAutocompletePreviewData, // if preview data is set, reset back to original search when input is hovered
        id: inputId,
        autocomplete: 'off',
    };
    const ref = useRef();

    const clearTextBox = () => {
        setAutocompleteModalOpen(false);
    };

    const clickAndFocusInput = () => {
        try {
            let input = ref.current.querySelector(`#${inputId}`);
            input.click(); // click to trigger hawksearch context changes to show the close button
            input.focus(); // shift focus back to input
        } catch (e) {}
    };

    const shouldFocusInputOnMobile = isMobile && mobileInputOpen;
    useEffect(() => {
        if (shouldFocusInputOnMobile) {
            clickAndFocusInput();
        }
    }, [shouldFocusInputOnMobile]);

    const openModal = () => {
        if (searchKey && searchKey.length > 0) setAutocompleteModalOpen(true);
    };

    const clickCloseButton = useCallback(() => {
        try {
            closeModalAndInputBar();
        } catch (e) {}

        try {
            closeSearch();
        } catch (e) {}
    }, [closeSearch, closeModalAndInputBar]);

    const shouldShowCloseButton = isMobile || autocompleteInputFocused;
    const closeButtonComponent = useMemo(() => {
        if (
            shouldShowCloseButton &&
            typeof closeModalAndInputBar === 'function' &&
            typeof clickCloseButton === 'function'
        ) {
            return (
                <ButtonIcon
                    icon={<X />}
                    variant="primary"
                    theme="dark"
                    size="medium"
                    tabindex={0}
                    aria-label="Close search modal"
                    onClick={clickCloseButton}
                    className="search-close-button"
                    onKeyDown={e => {
                        e.stopPropagation();
                        if (e.key === 'Enter') closeModalAndInputBar();
                    }}
                />
            );
        }

        return null;
    }, [shouldShowCloseButton, closeModalAndInputBar, clickCloseButton]);
    return (
        <div
            ref={ref}
            className={`search-header${autocompleteInputFocused ? ' focused' : ''}${
                autocompleteWithBreadcrumb ? ' ac-with-bc' : ''
            }`}
        >
            {autocompleteWithBreadcrumb && <SearchPageBreadcrumb isMobile />}
            <ActionTextBox
                {...props}
                clearOnClick={clickAndFocusInput}
                variation="hawksearch"
                placeholder="What are you looking for?"
                actionIcon="icon-search"
                hideClearIconOnEmptyText={!autocompleteInputFocused}
                value={searchKey}
                initialSearchKey={searchKey || initialSearchKey}
                setSearchKey={setSearchKey}
                setInputFocused={setAutocompleteInputFocused}
                onChange={setSearchKeyword}
                openModal={openModal}
                onClear={clearTextBox}
                onHover={resetAutocompletePreviewData}
                inputProps={inputProps}
                onInputFocusOut={closeModalAndInputBar}
                className="search-textbox"
                closeButtonComponent={closeButtonComponent}
                clearButtonComponent={
                    <Icon
                        size={32}
                        className="searchClearIcon"
                        name="x_clear_search"
                        iconProps={{ viewBox: '0 0 41.139 31', 'aria-label': 'Clear search term', tabindex: 0 }}
                    />
                }
                ClearButtonComponent={ClearButton}
                searchIconComponent={
                    <SearchIcon
                        updatedFromService={updatedFromService}
                        productSrpLink={productSrpLink}
                        getAutocompleteLinkData={getAutocompleteLinkData}
                        searchKey={searchKey}
                        pStoreID={pStoreID}
                        history={history}
                        closeModalAndInputBar={closeModalAndInputBar}
                        singleProductUrl={singleProductUrl}
                        redirectLocation={redirectLocation}
                    />
                }
                setAutocompleteStateOnClick={setAutocompleteStateOnClick}
            />
        </div>
    );
};
