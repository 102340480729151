import React, { useContext } from 'react';

import { useSelector } from 'react-redux';
import Typography from '@hpstellar/core/visId/Typography';
import GlobalLink from '@hpstellar/core/GlobalLink'
import { ChevronLeft } from '@hpstellar/vis-icons'

import Breadcrumbs from '../../page/components/breadcrumbs';
import SearchAutocompleteContext from './search-autocomplete-context';
import useSiteConfig from '../../hooks/useSiteConfig';
import useUserData, { getPStoreID } from '../../hooks/useUserData';
import { Helpers } from '../../core';

import './css/search-page-breadcrumb.less';

let getBreadCrumbs = Helpers.memoize((type, basename, pStoreID) => {
    // there are no equivalent lower environment urls
    const pStoreIdQs = pStoreID ? `?pStoreID=${pStoreID}` : '';
    const storeHome = `${basename || process.env.BASENAME || '/us-en/shop'}${pStoreIdQs}`;
    const homeHref = {
        'support': storeHome,
        'content': `https://www.hp.com/us-en/home.html${pStoreIdQs}`,
        'products': storeHome
    }
    const breadcrumbs = [
        {
            label: 'HOME',
            href: homeHref[type] || homeHref.products,
            gtmValue: 'click',
            id: 'HOME',
        },
        {
            //final crumb should always exclude href and not be clickable
            label: 'SEARCH',
            gtmValue: 'click',
            id: 'SEARCH',
        },
    ];

    return breadcrumbs;
}, (type, basename, pStoreID) => `${type}-${basename}-${pStoreID}`);

export default props => {
    let { type } = props || {};
    let siteConfig = useSiteConfig();
    let { basename } = siteConfig || {};
    let { userData } = useUserData();
    let pStoreID = getPStoreID(userData);
    
    let bc = getBreadCrumbs(type, basename, pStoreID);

    const acContext = useContext(SearchAutocompleteContext);
    const { autocompleteWithBreadcrumb, autocompleteModalOpen } = acContext || {};

    const isMobile = useSelector(state => {
        try{
            return state.ui.customBreakpoints.srp === 'mobile';
        }catch(e){}
    })

    if((autocompleteModalOpen && autocompleteWithBreadcrumb) || isMobile){
        const { label, href, gtmValue } = (bc && bc[0]) || {};
        return (
            <GlobalLink to={href} className='srp-bc srp-bc-mobile' gtmValue={gtmValue} gtmId='breadcrumb' gtmCategory='linkClick'>
                <ChevronLeft size='s' />
                <Typography 
                    variant='footnotes'
                    tag='span'
                >{`BACK TO ${label}`}
                </Typography>
            </GlobalLink>
        )
    }

    return (
        <Breadcrumbs
            breadcrumbs={bc}
            className='srp-bc'
        />
    )
}